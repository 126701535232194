<template>
  <div class="assessBox">
    <div class="app-container">
      <div class="titlebox">
        <el-button size="medium"
                   type="text"
                   @click="$router.go(-1)">返回</el-button>

        <div class="title">{{positive && positive.name}}-{{'转正详情'}}</div>
      </div>
      <div class="picbox">
        <div>
          <el-avatar :size="100"
                     :src="positive && positive.avatarUrl"></el-avatar>
        </div>
        <div>
          <h2>{{positive && positive.name}}</h2>
          <p>{{positive && positive.orgName}}&nbsp;&nbsp;{{positive && positive.jobName}}</p>
          <p>
            <span>手机号码：{{positive && positive.phoneNumber}}</span>
            <span>入职时间：{{positive && positive.entryTime}}</span>
          </p>
        </div>
      </div>
      <div class="viewbox">
        <div>
          <!-- <div style="">
            <div class="titleIcon"></div>详情
          </div> -->
          <div style="width:100%"
               class="positiveInfo">
            <div>
              <div>
                <label class="left_label">{{labels.date}}</label>
                <label class="right_label">
                  <div class="inputDiv">{{positive && positive.turnPositiveTime}}</div>
                </label>
              </div>
            </div>
            <div v-show="positive.remark">
              <div>
                <label class="left_label">{{labels.remark}}</label>
                <label class="right_label">
                  <div class="inputDiv "
                       style="height: 70px;">{{positive && positive.remark}}</div>
                </label>
              </div>
            </div>
            <div v-show="positive.fileUrlList&&positive.fileUrlList.length>0">
              <div>
                <label class="left_label">附件</label>
                <label class="right_label">
                  <div class="docContainer">
                    <div class="docContent"
                         style="display:flex;width:auto"
                         v-for="(file,index) in positive.fileUrlList"
                         :key="index">
                      <img src="/static/images/fj.png"
                           style="width:40px;height:40px;margin-right:10px">
                      <div style="width:auto;margin:0;padding-top: 10px;">
                        <span>{{file.name}}</span>
                        <br />
                        <a style="color:#409EFF;font-weight:normal"
                           target='_blank'
                           :href="'http://view.officeapps.live.com/op/view.aspx?src='+file.url">预览</a>
                        &nbsp;
                        <el-button type="text"
                                   @click="downLoadFile(file.url)">下载</el-button>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { getPositiveInfo } from '@/api/workbench';
export default {

  components: {
  },

  data () {
    return {
      params: {
        id: ''
      },
      staffId: '',
      positive: {
        default () {
          return {
            items: ''
          };
        }
      },
      labels: {
        date: '转正日期',
        remark: '转正评价'
      },
      type: ''
    };

  },

  created () {
  },
  watch: {
  },
  mounted () {
    this.$nextTick(function () {
      this.params.id = this.$route.query.id;
      this.type = this.$route.query.type;
      // if (this.type == '0') {
      //   this.labels.date = '转正日期';
      //   this.labels.remark = '转正评价'
      // } else if(this.type == '1'){
      //   this.labels.date = '延长日期';
      //   this.labels.remark = '延长原因'
      // }
      this.getPositiveInfo();
    });
  },
  methods: {
    downLoadFile (url) {
      window.open(url);
    },
    getPositiveInfo () {
      getPositiveInfo({
        request: {
          id: this.params.id
        }
      }).then((result) => {
        this.positive = result;
      }).catch(() => {
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.inputDiv {
  width: 60%;
  // height: 40px;
  line-height: 20px;
  display: inline-block;
  font-weight: normal;
  padding: 0 15px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: none;
  text-align: left;
  margin-bottom: 0 !important;
  vertical-align: top;
}
.docContainer {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-top: 10px;
  .docContent {
    display: flex;
    align-items: center;
    margin-left: 10px;
    flex-wrap: wrap;
    min-width: 140px;
    margin: 0;
  }
}
.picbox {
  padding: 0px 0 0 100px;
  background: #fff;
  margin: 0 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 140px;
  :first-child {
    margin-right: 10px;
  }
  h2 {
    margin: 10px 0 0 0;
  }
  p {
    margin: 15px 0px;
    color: #999999;
  }
}
.assessBox {
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .titlebox {
      text-align: center;
      line-height: 17px;
      background: #fff;
      font: bold 18px/25px "Microsoft YaHei";
      padding: 14px 40px;
      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }

      text-align: center;
      line-height: 17px;
      font: bold 18px/25px "Microsoft YaHei";
      margin: 20px;
    }
    .viewbox {
      margin: 0 20px;
      background: #ffffff;
      min-height: 300px;
      padding: 50px;
      .positiveInfo {
        font-size: 14px;
        div {
          margin-bottom: 10px;
        }
        .left_label {
          margin-right: 30px;
          color: #999999;
          text-align: right;
          width: 70px;
          display: inline-block;
          font-weight: normal;
        }
        .right_label {
          width: 70%;
        }
      }
    }

    .viewFoot {
      text-align: center;
      .el-button {
        margin: 15px;
        padding: 10px 45px;
      }
    }
  }
}
.titleIcon {
  display: inline-block;
  width: 8px !important;
  height: 20px;
  background: #498df0;
  border-radius: 5px;
  vertical-align: bottom;
  margin-right: 5px !important;
}
</style>

